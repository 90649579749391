
.mail__text {
    font-size: 1.2rem;
    text-align: center;
  }
  
  #mail__text_title {
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  
  .mailing__list_form {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }
  
  .mailing__list_input {
    width: 50%;
    border-radius: 3px;
    outline: none;
    border: none;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 1rem;
  }
  
  #mailing__list_button {
    outline: none;
    box-sizing: border-box;
    padding: 0 !important;
    border: none;
    font-size: 1.2rem;
    font-weight: normal;
    background-color:rgb(177, 177, 177);
  }
  
  #mailing__list_button:hover {
    background-color: rgb(255, 255, 255);
  }
  
  .mailing__list_input::placeholder {
    font-size: 1rem;
  }
  
.form-error p{
    margin:0;
    height:fit-content;
}
  

@media only screen and (min-width: 900px) {
  .form-width{
    width:60%;
    display:flex;
    flex-direction: column;
gap:15px;
justify-content: flex-start;
}
    .mail__text {
      text-align: left;
    }
  
    #mail__text_title {
      font-size: 1.6rem;
    }
  
  
    .mailing__list_input {
      width:400px;
    }
}  