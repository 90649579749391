@import "../theme-colors.scss";

.rank__holder{
  margin:0 10px;
  box-sizing:border-box;
  gap:1px !important;
}


#rank__1{
  @extend .rank__holder;
}

#rank__2{
  @extend .rank__holder;
}

#rank__3{
  @extend .rank__holder;
}


.rank__card_heading{
  display:flex;
  align-items: center;
  justify-content:space-between;
  width:100%;
  box-sizing: border-box;
  padding:20px 0px;
  border-bottom:1px solid #ffffff4b;
  background-color:#ffffff06;
font-size:0.8rem;
font-weight:bolder;
color:#ffffffcb;
}

.rank__card_heading_left{
display:flex;
flex-direction:row;
align-items: center;
justify-content: center;
gap:17px;
flex-basis:250px;
}
.rank__card_heading_right{
  font-size:1.2rem;
  font-weight:normal;
}

// .rank__card_heading:hover{
//   box-shadow: 0px 0px 10px $recent-card-bg;
//   z-index:2;
//   transition: box-shadow 0.2s;
//     border-radius:3px;
// }

.heading__collection_icon{
  display:block;
  width:25px;
  height:50px;
  border-radius:30px;
  background-color:black;
}
.rankcard__headingnm_price{
  display:flex;
  flex-direction:row;
  gap:20px;
}

.rank__card_heading_price{
display:flex;
flex-direction:row;
align-items: center;
  gap:4px;
  justify-content: center;
  flex-basis:140px;
}

#red__text{
  color:#CA351F;
}


.rank__card_heading_symb{
  background-image:url('../icons/eth.svg');
  background-size: cover;
  background-position:center;
  color:white;
height:27px;
width:27px;
filter: invert(1);
}


#rank__card_heading_owner{
  display:none;
}

#rank__card_heading_items{

display:none;
}

@media only screen and (min-width: 900px) and (max-width:1200px) {

}

@media only screen and (min-width: 900px){
  .rank__holder{
    margin:10px 20px;
  }

.rank__card_heading{
  background-color:transparent;
    padding:20px 25px 20px 20px;
    font-size:1.1rem;
}

#rank__card_heading_owner{
  display:flex;
}

#rank__card_heading_items{

display:flex;
}


.rank__card_heading_left{
  flex-basis:350px;
  justify-content: flex-start;
}
}

@media only screen and (min-width: 1200px) {


}
