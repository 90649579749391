@import "../theme-colors.scss";

.category__card{
  width:300px; //392
  height:247px;
  display:block;
  border:1px solid transparent;
  background-color:#ffffff6a;
  filter:invert(100);
  border-radius:3px;
box-sizing:border-box;
margin:auto;
background-size:contain;
background-repeat: no-repeat;
background-position: center;
}

.category__card:hover{
  border:1px solid black;
}

@media only screen and (min-width: 900px) and (max-width:1200px) {

}



@media only screen and (min-width: 900px){
.category__card{
  width:340px; //392
  height:247px;
}



}

@media only screen and (min-width: 1200px) {


}
