@import "../theme-colors.scss";

.info__card{
  display:flex;
  flex-direction:column;
  gap:20px;
  padding:50px 20px 50px 20px;
  border:1px solid #ffffff30;
  height:100%;
  a{
    color:$drop-text-bg;
  }
}
.info__text{
  text-align:center;
  font-size:0.9rem;
  width:200px;
  margin:auto;
color:#ffffff;
}

.info__title{
  text-align:center;
  font-weight:bold;
  font-size:1rem;
  text-align: center;
}

.info__card_icon{
display:block;
width:55px;
height:55px;
background-color:white;
background-repeat:no-repeat;
margin:auto;
background-size:80%;
filter:invert(100);
}




@media only screen and (min-width: 900px) {

  #info_part{
    max-width:fit-content;
  }



  .info__card{
    display:flex;
    flex-direction:column;
    gap:20px;
    padding:30px 20px 40px 20px;
    margin-bottom:auto;
width:250px;
  
    a{
      color:$drop-text-bg;
    }
  }

  .info__content{
    font-size:1rem;
    width:fit-content;
    text-align: center;
  }
}