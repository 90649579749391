@import './theme-colors.scss';


#product__square_2{
  padding:50px 0px;
  align-items: center;
}


.product__menu_bottom{
  display:block;
  height:200px;
  background-color:red;
  margin:0 20px;
  box-sizing: border-box;
}

#product__square_3{
  justify-content: space-between;
  gap:10px;
  height:620px;
}
@media only screen and (min-width: 900px) and (max-width:1200px) {

}

@media only screen and (min-width: 900px){

  #product__square_3{

gap:5px;

  }
}

@media only screen and (min-width: 1200px) {
  }
