@import "../theme-colors.scss";

.rank__holder{
  margin:0 10px;
  box-sizing:border-box;
  gap:1px !important;
}


#rank__1{
  @extend .rank__holder;
}

#rank__2{
  @extend .rank__holder;
}

#rank__3{
  @extend .rank__holder;
}


.rank__card{
  display:flex;
  align-items: center;
  justify-content:space-between;
  width:100%;
  box-sizing: border-box;
  padding:20px 0px;
  border:1px solid #ffffff1b;
  border-radius:5px;
  background-color:#ffffff06;
font-size:0.7rem;
font-weight:bolder;
color:white;
cursor:pointer;
transition: all 0.2s;
margin:10px 0px;
}



.rank__card_left{
display:flex;
flex-direction:row;
align-items: center;
justify-content: flex-start;
gap:10px;
flex-basis:250px;
flex-grow:1;
}
.rank__card_right{
  font-size:1.2rem;
  font-weight:normal;
}

.rank__card:hover{
  background-color:#252525;
  z-index:2;

    border-radius:3px;
}

.collection__icon ul{
margin:0;
padding-left:10px;
display:none;
}



.rankcard__nm_price{
  display:flex;
  flex-direction:column;
  gap:5px;
}

.rank__card_price{
  color:$icon-bg-color;
display:flex;
flex-direction:row;
align-items: center;
  gap:4px;
  flex-basis:120px;
  justify-content: center;

}
#subject{
  flex-grow:1;
  justify-content: flex-start;
}
#red__text{
  color:#CA351F;
}
#green__text{
  color:black;
  font-size: 0.4rem;
  flex-grow:1;
  max-width:fit-content!important;
  display: flex;
  padding:2px 5px;
  box-sizing: content-box;
  border-radius: 5px;;
  background-color:white;
}
.buffer{
flex-grow:1;
max-width:10%;
}



.rank__card_symb{
  background-image:url('../icons/eth.svg');
  background-size: cover;
  background-position:center;
  color:white;
height:27px;
width:27px;
filter: invert(1);
}
#rankcard_rank{
  display:none;
}

#rank__card_heading_owner{
  flex-grow:1;
  color:#ffffffab;
justify-content: flex-start;
padding-left:40px;
box-sizing: border-box;
}
@media only screen and (min-width: 900px) and (max-width:1200px) {

}

@media only screen and (min-width: 900px){
  .rank__holder{
    margin:10px 20px;
  }
  .collection__icon ul{display:inline-block;}
.rank__card{
  background-color:transparent;
font-size:0.95rem;
padding:20px;
}

.rank__card_left{
  gap:20px;
 flex-grow:1;
 
}

#rankcard_rank{
  display:flex;
}

.buffer{
  max-width:30%;
}

#green__text{
  color:black;
  flex-grow:2;
  max-width:fit-content;
  padding:2px 10px;
  border-radius: 5px;;
  background-color:white;
  font-size:0.8rem;
}


}

@media only screen and (min-width: 1200px) {


}
