@import '../theme-colors.scss';

.mySwiper{

}
.swiper-wrapper{
  padding: 10px 0!important;
}
.swipe-buttons{
  background-color:#FFFFFFab;
border: $searchbar-border-foc;
 border-radius:25px;
}
.swiper-button-prev{
 @extend .swipe-buttons;
  padding: 0px 10px;
  left:0;
  background-image:url("../icons/arrow_left.svg");
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(1);
}

.swiper-button-next{
 @extend .swipe-buttons;
 padding: 0px 10px;
 right:0;
 background-image:url("../icons/arrow_right.svg");
 background-position: center;
 background-repeat: no-repeat;
 filter: invert(1);
}

.swiper-button-prev:after{
  content:"" !important;
}

.swiper-button-next:after{
  content:"" !important;
}

.swiper-pagination-bullet{
  outline: $searchbar-border !important;
}
.swiper-pagination-bullet-active{
  background-color:#000000;
}

.swiper{
  width:92vw;
  margin: auto;
}



@media only screen and (min-width: 900px) and (max-width:1200px) {
  .swiper{

  }

}



@media only screen and (min-width: 900px){

}


@media only screen and (min-width: 1200px) {


}
