@import '../theme-colors.scss';

.product__data_holder{
  display:flex;
  flex-direction: column;
  padding:10px;
  box-sizing: border-box;
  width:400px;
  justify-content:flex-start;
align-items: center;
 background-color:transparent;
overflow-y: scroll;
margin:0 0 0 10px;
gap:10px;
flex-grow:1;
div{width:100%;}
}


.product__data_holder::-webkit-scrollbar {
width:10px;
}

.product__data_holder::-webkit-scrollbar-track{
  background-color:transparent;

}
.product__data_holder::-webkit-scrollbar-thumb{
  border:1px solid gray;
  border-radius:3px;
}

.product__data_holder::-webkit-scrollbar-thumb:hover{
  background-color:gray;
}


.product__data_heading{
  font-size:1.6rem;
font-weight:bold;
}

.product__data_sale{
display:flex;
  justify-content:space-between;
  padding:0px 80px 0 0;
box-sizing: border-box;
}

.product__data_royaltycollection{
display:flex;
flex-direction: column;
gap:30px;
}

.product__creator_royalty{
display:flex;
flex-direction:column;
gap:10px;
margin:auto;
}

.product__creator_tag{
display:inline-block;
background-color:transparent;
border:1px solid red;
height:50px;
width:200px!important;
border-radius:3px;
cursor:pointer;
}
.product__data_menu{
  border-bottom:1px solid red;
  padding:3px 0px;
  box-sizing: border-box;
  button{
    width:67px;
    height:30px;
    cursor:pointer;
  }
  button:active{
    border-bottom:3px solid blue;
    box-sizing: border-box;
  }
}


.product__menu_data{
  width:100%;
  display:flex;
  flex-direction: column;
  background-color:transparent;
gap:20px;
padding:10px 0px;
}

.product__owner_menu_item{
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
  gap:20px;
  flex-wrap:wrap;
}


.product__menu_card{
display:flex;
flex-direction:column;
gap:10px;
}

.product__owner_add_pic{
  display:block;
  width:40px!important;
  height:40px;
  border-radius:40px;
  background-color:blue;
}


@media only screen and (min-width: 900px) and (max-width:1200px) {

}

@media only screen and (min-width: 900px){
  .product__data_holder{
    width:470px;
    justify-content:flex-start;
  align-items:flex-start;

  }

  .product__data_royaltycollection{
    flex-direction:row;
  }

}

@media only screen and (min-width: 1200px) {
  }
