@import '../theme-colors.scss';


.search__holder{
  width:90%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  background-color:$navbar-search-bg;
  padding:2px;
margin: 0 auto;

    .header__search_input {
       background-color:$searchbar-bg;
       width:200px;
       height:38px;
       border:none;
       outline:none;
       border-top-left-radius:$border-radius;
       border-bottom-left-radius: $border-radius;
       transition: background-color 1s;
       padding: 0px 15px;
       box-sizing: border-box;
       font-size:0.9rem;
       color: $search-bar-text;
     }

}


.search__form{
  width:fit-content;
  display:flex;
  flex-direction: row;
  justify-content: center;
  border-radius:1px;
  border:$searchbar-border;


      button{
        width:40px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border:$search-but-border;
        outline:none;
        box-sizing: border-box;
        background-color: $search-button-bg;
        color:$search-button-text;

      }
      button:hover{
        background-color: $search-button-bg-hov;
        border:$search-but-border-hov;
        color:$highlight-color-dark;
        cursor: pointer;
      }

      button:active{
        border:$search-but-border-foc;
        transition:border 1s;
        background-color:$search-button-bg-active;
        color:$search-button-active-text;
      }

}

.search__form:hover{
    border:$searchbar-border-hov;
}
.search__form:focus-within{
    border: $searchbar-border-foc;
    transition: border 1s;
}



.search__form:hover .header__search_input{
     background-color:$searchbar-bg-hov;
}

.search__form:focus-within .header__search_input{
background-color:$searchbar-bg-foc;
}



.header__search_input::placeholder{
        color:$search-bar-text;
      }

.header__search_input:focus-within::placeholder{
    color:$search-bar-active-text;
    transition: color 1s;
}

.search__form:hover .header__search_input::placeholder{
  color:$search-bar-text-hov;
}


@media only screen and (min-width: 900px) and (max-width:1200px) {

}



@media only screen and (min-width: 900px){

.search__holder{
  margin:0 auto 0 0;
}

  .search__form{
    width:98%;

      button{
        width:85px;
      }

      .header__search_input {
        flex-grow:1;
      }

  }

}


@media only screen and (min-width: 1200px) {


}
