@import "../theme-colors.scss";




.trend__card{
  width:339px; //392
  height:339px;
  display:flex;
  border-radius:3px;
  background-color:white;
margin:auto;
overflow:hidden;
}

.trend__card:hover{
  box-shadow:$feature-card-shadow-hov;
  cursor:pointer;
}


.image-holder--trend{
  margin:auto;

  display:block;
  width:100%;
  height:100%;
  background-size:90%;
background-repeat: no-repeat;
background-position: center;
transition: transform 1s ease-in-out;
}


.trend__card:hover .image-holder--trend{
  transform:scale(1.1)
}


@media only screen and (min-width: 900px) and (max-width:1200px) {

}



@media only screen and (min-width: 900px){
.trend__card{
  width:392px;
  height:339px;
  background-size:contain;


}



}

@media only screen and (min-width: 1200px) {


}
