@import './theme-colors.scss';

html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
button{
color:#000000!important;  
}
#bigbasket-case{
  background-image: url("./icons/bigbasket_case.png");

}

#duo-case{
  background-image: url("./icons/duolingo_case.png");
}

#figma-case{
  background-image: url("./icons/figmaui.png");
  background-size:cover;
  background-color:#444444;
}


#flipkart-logo{
  background-image:url("./icons/flipkart.png");
}


#byjus-logo{
  background-image:url("./icons/byjus.png");
  background-size:70%;
}

#streak-logo{
  background-image:url("./icons/streak(1).png");
  background-size:80%;
}


#consulting{
  background-image: url("./icons/consulting.png");
}

#strategy{
  background-image: url("./icons/strategy.png");
}

#implementation{
  background-image: url("./icons/implementation.png");
}

#development{
  background-image: url("./icons/development.png");
}



#instagram{
  background-image: url("./icons/instagram.png");
}

#linkedin{
  background-image: url("./icons/linkedin.png");
}

#threads{
  background-image: url("./icons/threads.png");
}

#xicon{
  background-image: url("./icons/x.png");
}



body { background-image: linear-gradient(to bottom, #f3f3f3,#f3f3f3,#f3f3f3, black,black, black,black, black, #00000000);
  margin: 0;
  background-color: $background-top;
  font-family: "Saira", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-color-green
{
  color:#000000;
}


#tsparticles {
  position: absolute;
  height: 1300px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  margin-top: 65px;
  z-index: -1;
  opacity: 0.8;
  mask-image: linear-gradient(to top, $background-transparent, $background-top 25%, $background-top 75%, $background-transparent);
}

a {
  text-decoration: none;
  color: black;
}

.app__holder {
  margin: auto;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  height: fit-content;
  padding-bottom: 30px;

}

.app__body {
  width: 100vw;
  max-width: $max-width-app;
  margin: auto;
  padding: 70px 0 0 0;
  color: #000000;
  display: flex;
  flex-direction: column;
  background-color: $app-bg;
}

span {
  background-color: $span-bg-color;
}
.hover_letter_holder{
  display:inline-flex;
  width:fit-content;
  justify-content:center;
}
.hover_letter{
  background-color:#00000000;

flex-grow:1;
display:inline-flex;
justify-content: center;
transition:all 1s ease-in;
}

.hover_letter:hover{
  color:#ffffff;
  transition:all 0.2s ease-out;
}


@keyframes blink {
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color:#000000;
    color:#ffffff;
  }
}

.blinking-span {
  // animation: blink 1.2s infinite;
}

//sections

.section__title {
  flex-direction: row;
  justify-content: center;
  padding: 50px 20px 40px 20px;
  box-sizing: border-box;
}

.all__section {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

#section__1 {
  padding: 20px 20px 30px 20px !important;
  background-size: cover;
}

#section__2 {
  @extend .section__title;
  background-color: $section-2-bg;
  padding:50px 20px 20px 20px;
}

#section__3 {
  max-width: 99vw !important;
  padding: 0 20px;

  margin-bottom:0!important;
}

#section__4 {
  @extend .section__title;
  padding: 70px 20px 0px 20px;
}

#section__5 {
  max-width: 99vw !important;
  padding: 0 20px;

  gap: 20px;
  padding-bottom:20px
}

#section__6 {
  background-color: $section-6-bg;
}

#ranking__partition {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

#section__7 {
  @extend #section__2;
}


#section__8 {
  display: flex !important;
  box-sizing: border-box;
  max-width: 1536px !important;
}

#section__9 {
  @extend #section__2;
}

#section__10 {
  padding: 0;
  gap: 50px;
  justify-content: space-around;
}

#section__11 {
  @extend #section__2;
}

#section__12 {
  @extend #section__8;
}

#section__17 {
  background-color: transparent;
  padding: 40px;
}

#section__18 {
  background-color: $section-8-bg;
  padding: 30px;
}

//sections-end

.section__partition {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  gap: 20px;
}

#square__1 {
  padding: 20px 10px 35px 10px;
}

#square__2 {
  padding: 20px 0px;
}

//section partitions end

.heading__1 {
  font-size: 3.2rem;
  margin: 0;
}

.heading__2 , .heading__2a {
  font-size: 1.4rem;
  font-style: bold;
  margin: 0;
}

.heading__2a, .heading__2b{
  font-weight:600;
}

.heading__2b {
  font-size: 1.6rem;
  font-style: bold;
  color:white;
  margin: 0;
}


.learn__more {
  font-size: 1.1rem;
  cursor: pointer;
  margin: auto 5px auto 0px;
  color:white;
  text-align: center;
}

.button__holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
  margin: 15px 0;
}

.home__button {
  width: fit-content;
  min-width: 140px;
  padding: 0 20px;
  height: 50px;
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3px;
  background-color: $home-button-bg;
  border:1px solid black;
  outline: none !important;
}


.home__button:active {
  background-color: $home-button-act-bg;
  color: $navbar-icon-text-hov;
}


#section__bottom_text {
  display: flex;
  flex-direction: row;

background-color:#000000;
  border: 1px solid #ffffffab;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  padding: 15px 25px;
  margin-top: 10px;
  border-radius:5px;
}

#section__bottom_text:hover {
  box-shadow: 5px 5px 0px #ffffff;
  border: 1px solid #ffffff;
  border-radius:5px;
}

#section__bottom_text:active {
  box-shadow: none;
  background-color:#ffffff;
}

#section__bottom_text:active .learn__more{
  color:black!important;
}


#section__bottom_text:active .arrow-right--learn-more {
  filter: invert(0%) sepia(39%) saturate(7477%) hue-rotate(163deg) brightness(105%) contrast(93%);
}


#recent__drops {
  flex-direction: column !important;
  justify-content: center;
}

#explore__button {}


#top__collection {
  display: flex;
  gap: 15px;
  flex-direction: column !important;
}

#drop_text {
  color: $drop-text-bg;
  cursor: pointer;
}

.footer__width_control {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap:wrap;
}

#footer__1 {
  justify-content: flex-start;
}

#footer__2 {
  @extend #footer__1;
}

.community__links_holder {
  display: block;
  width:100%;
  height: 75px;
  margin:0 auto;
  display:flex;
  justify-content: space-between;
}

footer {
  color: $app-text-color;
}

.arrow-right--learn-more {
  height: 100%;
  width: 25px;
  color: white;
  background-image: url("./icons/arrow_right.svg");
  background-size: 65%;
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  filter: invert(95%) sepia(32%) saturate(0%) hue-rotate(280deg) brightness(112%) contrast(106%);
}



.socials__icon{
  display:block;
  width:55px;
  height:55px;
border:1px solid rgb(141, 141, 141);
  background-repeat:no-repeat;
  background-position: center;
  margin:auto 0;
  background-size:80%;
  filter:invert(100);
  }

  .socials__icon:hover{
    cursor:pointer;
  }
  
  #section__18 {
    background-color: #ffffff1a;
    border-top:5px solid #ffffffcb;
padding-top:80px;
  }

  .text__holder {
    line-height:60px;
    display: flex;
        flex-direction: column;
  }

  #section__bottom_text {
    margin: 20px 0 0 0;
  }

.text__holder2{
display:flex;
flex-direction: column;
}
//phone-end

@media only screen and (min-width: 900px) and (max-width:1200px) {}



@media only screen and (min-width: 900px) {
  
#tsparticles {
 height: 725px; 
 opacity: 0.7;
}
body { 
  background-image: linear-gradient(to bottom, #f3f3f3,#f3f3f3, black,black, black, #00000000);
}
  .all__section {
    display: flex;
    flex-direction: row;
    padding: 0 120px;
  }

  .section__partition {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    gap: 15px;
  }

  #section__1 {
    padding: 0 120px !important;

  }

  #section__2 {}

  #section__3 {
    display: flex !important;
    padding: 0px 120px 0px 120px !important;
    box-sizing: border-box;
    max-width: 1536px !important;
  }

  #section__4 {}

  #section__5 {
    display: flex !important;
    padding: 0px 120px 20px 120px !important;
    box-sizing: border-box;
    max-width: 1536px !important;
    gap: 0px !important;
  }

  #section__6 {}

  #section__8 {}

  #section__10 {
    gap: 30px;
    max-width: 1536px !important;
    padding:0 130px;
  }

  #section__17 {}

  #section__18 {
    padding: 50px 120px;
    display:flex;
    border-top:5px solid rgba(255, 255, 255, 0.151);
  }


  .footer__width_control {
    border-radius: 0px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: $section-8-border-bottom;
  }


  #square__1 {
    padding: 20px 30px 50px 30px;
  }

  #square__2 {
    padding: 0px;
  }

  .text__holder {
    display: flex;
    flex-direction: column;
    line-height:none;
  }

  .text__holder2 {
    display: flex;
    flex-direction: column;
    line-height:none;
  }

  #top__collection {
    flex-direction: row !important;
    gap: 8px;
  }

  .heading__1 {
    font-size: 3.2rem;
    margin: 0;
  }

  .heading__2 {
    font-size: 1.9rem;
    margin: 0;
  }

  .heading__2b {
    font-size: 1.9rem;
    margin: 0;
  }

  .button__holder {
    margin: 30px 0;
  }


  #recent__drops {
    flex-direction: row !important;
  }

  #footer__1 {
    padding: 20px 100px 20px 20px;
    gap: 25px;
    max-width:40%;
    margin:0 auto;
  }

  #footer__2 {}

  .community__links_holder {

    display:flex;
  }


}


@media only screen and (min-width: 1200px) {}