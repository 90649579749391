@import '../theme-colors.scss';



.menu__holder{
  background-color:none;
  box-sizing: border-box;
}

#menu__phone{
  background-color:$menu-phone-color;
  position:fixed;
  top:55px;
  display:flex;
  flex-direction: column;
  width:97vw;
  justify-content: center;
  padding:10px 5px 15px 5px;
  gap:10px;
  margin:auto;
  display:none;
}


.menu-item{
  display:flex;
  width:90%;
  margin:auto;
  justify-content: center;
  background-color:$navbar-icon;
  height:48px;
  padding:5px;
  outline:none;
  color:$navbar-icon-text;
  border:none;

  .icon__holder{
    font-size:1.1rem;
    margin:auto;
    padding: 0px 2px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    height:fit-content;

    .icon{
      width:20px;
      height:20px;
      background-position:center;
      background-size: cover;
      display:block;
      margin:auto;
      z-index:2;
    //  mask-image:linear-gradient(to left, #000000ab,#000000ab);
    }

    #explore-svg{
        background-image:url("../icons/explore.svg");
    }

#stat-svg{
        background-image:url("../icons/stat.svg");
}

#profile-svg{
      background-image:url("../icons/profile.svg");
}

#wallet-svg{
      background-image:url("../icons/wallet.svg");
}


    .icon__text{
      margin: auto 0px auto 10px;
      height:fit-content;
      display:inline-block;
      font-size:0.9rem;
      font-weight:normal;
    }
  }

}
.menu-item:hover{
    background-color:$navbar-icon-hov;
    color:$navbar-icon-text-hov;
    cursor: pointer;
    box-shadow: $navbar-icon-shadow-hover;
    transition: background-color 0.4s, color 0.4s,box-shadow 0.4s;

    .icon{
       mask-image:none;
    }
  }

#menu__desktop{
  display:none;
}


  @media only screen and (min-width: 900px) and (max-width:1200px) {

  }



  @media only screen and (min-width: 900px){

.icon__text{
  position:absolute;
  background-color:$icon-bg-color;
  top:55px;
  display:none !important;
  padding:3px 5px;
  border-radius:3px;
  color:$navbar-icon-text-hov;

}

.menu-item:hover .icon__text{
  display:block !important;
  font-weight:300;
}

  #menu__phone{
    display:none;
  }
#menu__desktop{
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
  width:fit-content;
  position:relative;
  width:100%;
gap:2px;
margin: auto 0px;
}

.menu-item{
  width:fit-content;
  box-sizing: border-box;
  margin:auto 10px;
  height:40px;
  padding:5px 10px;

i {
  font-size:1rem;
  margin:auto 5px;

span{
  font-size:1rem;
  margin: auto 0px;
}
}


}

  }


  @media only screen and (min-width: 1200px) {


  }
