@import '../theme-colors.scss';

.product__card{
  display:block;
  width:400px;
 height:450px;
  margin:auto;
background-color:$feature-card-bg;
border-radius: 3px;
transition: box-shadow 0.6s;
}

.product__card:hover{
  box-shadow: $feature-card-shadow-hov;
}


@media only screen and (min-width: 900px) and (max-width:1200px) {

}

@media only screen and (min-width: 900px){
.product__card{
  width:650px;
  height:620px;

}

}

@media only screen and (min-width: 1200px) {
  }
