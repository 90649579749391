// theme variables
@import '../theme-colors.scss';

.topnavbar{
  display:flex;
  width:100vw;
  height:fit-content;
  margin:auto;
  opacity:$navbar-opacity;

position:fixed;
z-index:100;
box-shadow:0px 2px 10px #000000ab;



background-image: radial-gradient(circle, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.topnavbar:hover{
opacity:$navbar-opacity-hover;
}

/*
.colors{
https://colorhunt.co/palette/0000003e065f700b978e05c2
} */
.topnavbar__width-control{
  width:100%;
  margin:auto;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  gap:5px;
  
  padding: 8px 20px;
 box-shadow: $navbar-shadow;
}

.navbar__item{
display:flex;
flex-direction:column;
justify-content:center;
position:relative;
flex-grow: 1;
height:48px;

}

#logo{
  justify-content: flex-start;
  flex-basis:60px;
  padding: 0 8px 0 0;
}

#search{

}
#menu{
    flex-basis:90px;
    justify-content: flex-end !important;
    flex-direction:row;
}
.comp__logo{
  width:fit-content;
  height:90%;
  color:#000000;
  display:flex;
  margin:auto auto auto 0;
  align-items: center;
}

.logoholder{
  display:flex;
  flex-direction: row;
}
.logo_image{
    width:40px;
  height:40px;
  border-radius: 3px;
  margin:auto 5px;
  background-size:60%;
  background-position:center;
  background-color: transparent;
border:1px solid black;
background-repeat:no-repeat;
color:black;
display:flex;
}



.logo_image h3{
  margin:auto;
}


.comp__logo h1{
  margin:0;
  font-size: 2rem;
padding:0px 10px;
}
@media only screen and (min-width: 900px) and (max-width:1200px) {

}



@media only screen and (min-width: 900px){

  .topnavbar{
    position:absolute;
    display:flex;
    width:100vw;
   box-sizing: border-box;
  }

.topnavbar__width-control{
  max-width:1536px;
  border-radius:0px;
  margin:auto;
  box-sizing: border-box;
  padding:5px 120px;
}
.navbar__item{
  height:52px;
}

#logo{
  flex-grow:0;
  flex-basis:295px;
  padding:0;
  display:flex;
}

.comp__logo{
  width:250px;
  height:90%;
  // background-image:url("../icons/logo.svg");
  // background-position:left;
  // background-size:cover;
  color:#000000;
  display:flex;
  margin:auto;

}
.comp__logo h1{
margin:auto 0;
font-size:1.5rem;
width:fit-content;
// color:#6AFF7F;

padding:5px 10px;
}
#menu{
  flex-grow:0.5;
  flex-direction:row;
  justify-content: flex-end;
padding:0px 0px 0px 20px;
}

#menu__button{
  display:none;
}

#search{
  flex-grow:2;
align-items:flex-start;
}


}


@media only screen and (min-width: 1200px) {


}
