@import "../theme-colors.scss";

.recent__card{
  display:flex;
  flex-direction:column;
  align-items:center;
  box-sizing: border-box;
  height:563px;
  width:324px;
  background-color:red;
  border-radius:$border-radius;
  margin:auto;
  background-color:#ffffff;
  border:1px solid #000000;
  padding:10px;
}
.recent__card:hover{
  z-index:3;

}
.recent__card_img{
width:100%;
max-height:246px;
display:block;
flex-grow:1;
background-color:white;
border-radius:inherit;
}
.creator__logo_holder:hover{
  cursor:pointer;
  color:white;
}

.creator__logo_holder{
  padding:10px 10px;
  visibility: hidden;
  opacity:0;
  transition: opacity 0.5s;
  z-index:-5;
}

.recent__card:hover .creator__logo_holder{
  display:flex;
  opacity: 1;
visibility: visible;
}

.creator__logo{
  width:40px;
  height:40px;
  border-radius:50px;
  align-items: center;
  background-color:white;
  margin:10px;
  background-image:url(".././icons/github.png");
  background-size:150%;
  background-position: center;
  background-repeat: no-repeat;

}

.creator__name{
  position:absolute;
  top:26px;
  left:120px;
  background-color:black;
  padding:5px 8px;
  border-radius:3px;
  opacity:0;
}

.creator__logo:hover > .creator__name{
  opacity:1;
  transition: opacity 0.5s;
}
.recent__card_info{
background-image:#000000;
border-radius: inherit;
border-top-left-radius: 0px;
border-top-right-radius: 0px;
width:100%;
display:flex;
flex-direction:column;
align-items: center;
justify-content: space-between;
gap:45px;
padding: 30px 50px 40px 50px;
box-sizing: border-box;
}

.recent__card_text{
display: flex;
flex-direction: column;
gap:35px;
align-items: center;
}

.recent__card_title{
font-size:1.4rem;
}

.recent__card_info_text{
  font-size: 1.1rem;
  text-align: center;
}

.recent__card_button{

outline:none;
border:none;
padding:8px 15px;
border-radius:3px;
font-weight:bold;
}

.recent__card_button:hover{
  cursor:pointer;
}
.banking{
  background-image:url("../icons/banking.png");
background-position:center;
background-size:120%;
background-repeat:no-repeat;
}


.portfolio{
  background-image:url("../icons/portfolio.png");
background-position:center;
background-size:190%;
background-repeat:no-repeat;
}


.livereaction{
background-image:url("../icons/livereaction.png");
background-position:center;
background-size:190%;
background-repeat:no-repeat;
}


@media only screen and (min-width: 900px) and (max-width:1200px) {

}



@media only screen and (min-width: 900px){

  .recent__card{
    height:603px;
    width:394px;
  }


.creator__name{
  left:90px;
}


}


@media only screen and (min-width: 1200px) {


}
